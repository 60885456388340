import _ from 'lodash';
import { WithTranslation, withTranslation } from 'react-i18next';
import style from './style.module.css';
import { useForm } from 'react-hook-form';
import FormGroup from '../FormGroup';
import Input from '../Input/index';
import ButtonsContainer from '../ButtonsContainer';
import Button from '../Button/index';
import { useCallback, useEffect, useRef, useState } from 'react';
import Action from '../Action';
import { VideoPlayer } from '../VideoPlayer';
import { getVideoFromId, VideoFromYt } from '../../stores/youtube';
import { PRODUCTION, YOUTUBE_API_KEY } from '../../config';
import { useNavigation } from '../../pages/ContextMenu';

const API_KEY = YOUTUBE_API_KEY;
const delay = PRODUCTION ? 300 : 1000;

interface ImportYoutubeProps extends WithTranslation {
    onReturn: () => void;
    onImport: (data: any) => void;
    onChangeTitle?: (title: string) => void;
    data: any;
};

interface YoutubeSearchOptions {
    key: string;
    term: string;
    pageToken?: string;
};

const extractVideoId = (url: string) => {
    const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
};

const getVideoTitleFromUrl = async (url: string, apiKey: string): Promise<string> => {
    const videoId = extractVideoId(url);
    if (!videoId) {
        throw new Error('Invalid YouTube URL');
    }

    const API_URL = 'https://www.googleapis.com/youtube/v3/videos';
    const params = {
        part: 'snippet',
        id: videoId,
        key: apiKey,
    };

    const response = await fetch(`${API_URL}?${new URLSearchParams(params)}`);
    const data = await response.json();

    if (data.error) {
        throw new Error(data.error.message);
    }

    if (data.items && data.items.length > 0) {
        return data.items[0].snippet.title;
    } else {
        throw new Error('Video not found');
    }
};

const youtubeSearch = (options: YoutubeSearchOptions, callback: any) => {
    if (!options.key) {
        throw new Error('Youtube Search expected key, received undefined');
    }

    if (!options.term) {
        throw new Error('Youtube Search expected term, received undefined');
    }

    const API_URL = 'https://www.googleapis.com/youtube/v3/search';

    const params = {
        part: 'snippet',
        key: options.key,
        q: options.term,
        type: 'video',
        pageToken: options.pageToken || '',
    };

    fetch(`${API_URL}?${new URLSearchParams(params)}`)
        .then((response) => response.json())
        .then((data) => {
            if (data.error) {
                callback(data.error);
            } else {
                callback(data);
            }
        });
}

const ImportYoutube = ({ t, onReturn, onImport, data: toEdit }: ImportYoutubeProps) => {
    const { subscribe, setTitle } = useNavigation();
    const [videos, setVideos] = useState([]);
    const [nextPageToken, setNextPageToken] = useState<string | null>(null);
    const [selectedVideo, setSelectedVideo] = useState<any>(null);
    const [state, setState] = useState('');
    const {
        watch,
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { isValid, errors },
    } = useForm<{ video: any, searched: string, thumbnail: string, fileName: string }>({
        mode: 'all',
        defaultValues: {
            video: [],
            searched: '',
            thumbnail: '',
            fileName: '',
        },
    });

    useEffect(() => {
        const handleReturn = () => {
            if (state !== '') {
                setState('');
                return;
            }
            if (onReturn) onReturn();
        };

        if (state === 'TITLE') {
            setTitle(t('Saisir un titre'));
        } else {
            setTitle(t('Importer une vidéo Youtube'));
        }
        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, [state]);

    useEffect(() => {
        if (toEdit) {
            setValue('video', toEdit?.[0]?.url, { shouldValidate: true });
            setValue('fileName', toEdit?.[0]?.title);
            setValue('thumbnail', toEdit?.[0]?.thumbnail);
        }
    }, [toEdit]);

    const searched = watch('searched');

    const searchVideos = (searchTerm: string, pageToken?: string) => {
        youtubeSearch({ key: API_KEY, term: searchTerm, pageToken }, (data: any) => {
            setVideos(prevVideos => pageToken ? [...prevVideos, ...data.items] : data.items);
            setNextPageToken(data.nextPageToken || null);
        });
    };

    const debouncedSearch = useCallback(_.debounce(searchVideos, delay), []);

    useEffect(() => {
        if (searched) {
            debouncedSearch(searched);
        }
    }, [searched, debouncedSearch]);

    useEffect(() => {
        if (selectedVideo) {
            setValue('video', `https://www.youtube.com/watch?v=${selectedVideo.id.videoId}`, { shouldValidate: true });
            setValue('fileName', selectedVideo.snippet.title);
            setValue('thumbnail', selectedVideo.snippet.thumbnails.high.url);
        }
    }, [selectedVideo]);

    const validateYouTubeUrlWithAPI = async (url: string) => {
        const videoId = extractVideoId(url);
        if (!videoId) {
            return false;
        }

        return true;
    };

    const onSubmit = async (data: any) => {
        if (!data.thumbnail) {
            const videoId = extractVideoId(data.video) ?? '';
            const video = await getVideoFromId(videoId) as any;
            if (video && (video as VideoFromYt)?.thumbnail) {
                data.thumbnail = video.thumbnail;
            } else {
                setError('video', { type: 'manual' });
                return;
            }
        }
        onImport([{
            url: data.video,
            thumbnail: data.thumbnail,
            title: data.fileName,
        }]);
    };

    const video = watch('video');
    const fileName = watch('fileName');

    useEffect(() => {
        const fetchTitle = async () => {
            const title = await getVideoTitleFromUrl(video, API_KEY);
            setValue('fileName', title, { shouldValidate: true });
        };
        if (video.length && !fileName) fetchTitle();
    }, [video]);

    if (state === 'TITLE') {
        return (
            <div>
                <div className={style.subTitle}>{t(`- Option -
Saisissez un titre court et explicite.`)}</div>
                <div className={style.selectedVideo}>
                    <VideoPlayer url={video || toEdit?.[0]} />
                    <div className={style.youtubeTitle}>{selectedVideo?.snippet?.title}</div>
                    <div className={style.youtubeChannel}>{selectedVideo?.snippet?.channelTitle}</div>
                </div>
                <FormGroup>
                    <Input register={register('fileName')} placeholder={t('Nom de la vidéo') as string} />
                </FormGroup>
                <ButtonsContainer position='CENTER'>
                    <Button size='LARGE' theme='PRIMARY' action={handleSubmit(onSubmit)} label={t('Modifier ma vidéo')} />
                </ButtonsContainer>
            </div>
        );
    }

    return (
        <>
            <div className={style.subTitle}>{t('Saisissez des mots clés pour rechercher une vidéo Youtube.')}</div>
            <FormGroup>
                <Input register={register('searched')} placeholder={t('Saisir un ou plusieurs mots clés') as string} />
            </FormGroup>

            {!selectedVideo && toEdit?.[0] && <>
                <div className={style.selectedVideo}>
                    <div className={style.title}>{toEdit[0].title}</div>
                    <VideoPlayer url={toEdit[0].url} />
                    <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                        <Button className={style.fullWidth} size='MEDIUM' theme='PRIMARY' action={() => setState('TITLE')} label={t('Choisir cette video')} />
                    </ButtonsContainer>
                </div>
            </>}

            {!!videos?.length && <>
                <div className={style.videos}>
                    {videos.map((video: any, idx) => (
                        <div key={`${video.etag}-${idx}`}>
                            {!!selectedVideo && selectedVideo.id.videoId === video.id.videoId ?
                                <div className={style.selectedVideo}>
                                    <div>
                                        <div className={style.youtubeTitle}>{selectedVideo.snippet.title}</div>
                                        <div className={style.youtubeChannel}>{selectedVideo.snippet.channelTitle}</div>
                                    </div>
                                    <VideoPlayer url={`https://www.youtube.com/watch?v=${selectedVideo.id.videoId}`} />
                                    <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                                        <Button size='LARGE' theme='PRIMARY' action={() => setState('TITLE')} label={t('Choisir cette video')} />
                                    </ButtonsContainer>
                                </div>
                                :
                                <Action className={style.video} action={() => setSelectedVideo(video)}>
                                    <img src={video.snippet.thumbnails.default.url} alt={video.snippet.title} />
                                    <div className={style.title}>{video.snippet.title}</div>
                                </Action>
                            }
                        </div>
                    ))}
                </div>
                {nextPageToken && (
                    <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                        <Button className={style.fullWidth} size='MEDIUM' theme='SECONDARY' action={() => searchVideos(searched, nextPageToken)} label={t('Voir plus de résultats')} />
                    </ButtonsContainer>
                )}
            </>}

            <div className={style.subTitle}>{t('Ou collez directement l’URL de la vidéo de votre choix.')}</div>

            {!!video.length && ![toEdit?.[0]?.url, `https://www.youtube.com/watch?v=${selectedVideo?.id?.videoId}`].includes(video) && <>
                <div className={style.selectedVideo}>
                    <VideoPlayer url={video} />
                    <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                        <Button size='LARGE' theme='PRIMARY' action={() => setState('TITLE')} label={t('Choisir cette video')} />
                    </ButtonsContainer>
                </div>
            </>}

            <FormGroup>
                <Input register={register('video', { required: true, validate: validateYouTubeUrlWithAPI })} placeholder={t('Coller une URL') as string} />
                {errors.video && <span className={style.error}>{t('URL YouTube invalide')}</span>}
            </FormGroup>
        </>
    );
};

export default withTranslation()(ImportYoutube);