import { WithTranslation, withTranslation } from "react-i18next";
import MenuHeader from "../MenuHeader";
import { Button } from "../Button/index";
import { useContext, useEffect } from "react";
import { UserContext } from "../../providers/UserProvider";
import { Share } from '@capacitor/share';
import style from "./style.module.css";
import { useNavigation } from "../../pages/ContextMenu";

interface InviteMembersProps extends WithTranslation {
    onReturn?: () => void;
}

const InviteMembers = ({ t, onReturn }: InviteMembersProps) => {
    const { subscribe, setTitle } = useNavigation();
    const userDataProp = useContext(UserContext);
    const codeBe = userDataProp?.user?.familyCode;

    useEffect(() => {
        const handleReturn = () => {
            if (onReturn) onReturn();
        };

        setTitle(t('Inviter une bonne étoile'));
        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, []);

    return (
        <>
            <div className={style.title}>{t('Code bonne étoile')}</div>
            <div className={style.codeBe}>{codeBe}</div>
            <div className={style.description}>{t('En partageant mon “code bonne étoile” à mes proches et/ou professionnels de confiance, j’accepte qu’ils puissent rejoindre mon espace Stimul’in sans  coût supplémentaire et qu’ils profitent de toutes les fonctionnalités mises à leur disposition (partage et consultation des photos, vidéos et messages, envoi de jeux surprises personnalisés,…)')}</div>
            <Button label={t('Inviter des bonnes étoiles')} action={() => Share.share({
                title: "Invitation à rejoindre Stimul'in!",
                url: `https://appv1.stimulin.fr/register/${codeBe}`,
            })} />
        </>
    )
};

export default withTranslation()(InviteMembers);