import { WithTranslation, withTranslation } from "react-i18next";
import DataIllu from './assets/data.svg?react';
import style from './style.module.css';

interface MaintenanceProps extends WithTranslation {}

const Maintenance = ({ t }: MaintenanceProps) => {
    return (
        <div>
            <div className={style.subTitle}>{t('(Re)Découvrez votre rubrique statistiques dans quelques jours... Elle se refait une beauté !')}</div>
            <DataIllu className={style.dataIllu} />
        </div>
    );
};

export default withTranslation()(Maintenance);