import CrossIcon from '../../assets/cross.svg?react';
import ChevronIcon from '../../assets/back.svg?react';
import Action from '../Action';
import style from './style.module.css';
import { useNavigation } from '../../pages/ContextMenu';

interface MenuHeaderProps {
    onReturn?: () => void;
    onClose?: () => void;
}


const MenuHeader: React.FC<MenuHeaderProps> = ({ onReturn, onClose }) => {
    const { handleReturnClick, title } = useNavigation();

    const handleReturn = () => {
        handleReturnClick();
        if (onReturn) onReturn();
    };
    
    return (
        <div className={style.menuHeader}>
            {<Action className={style.menuHeaderReturn} action={handleReturn}>
                <ChevronIcon style={{ width: '100%', height: '100%' } as React.CSSProperties} />
            </Action>}
            <div className={style.menuHeaderTitle}>{title}</div>
            {onClose && <Action className={style.menuHeaderClose} action={onClose}>
                <CrossIcon style={{ width: '100%', height: '100%' } as React.CSSProperties} />
            </Action>}
        </div>
    );
};

export default MenuHeader;
