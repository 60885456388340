import { WithTranslation, withTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import style from './style.module.css';
import FormGrid from '../FormGrid';
import FormGroup from '../FormGroup';
import Input from '../Input/index';
import Button from '../Button/index';
import ButtonsContainer from '../ButtonsContainer';
import UploadInput from '../UploadInput';
import { useNavigation } from '../../pages/ContextMenu';
import { useContext, useEffect } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { saveUser } from '../../stores/User';

interface AccountProps extends WithTranslation {
    onReturn?: () => void;
}

const Account = ({ t, onReturn }: AccountProps) => {
    const { subscribe, setTitle } = useNavigation();
    const userDataProp = useContext(UserContext);
    const {
        watch,
        register,
        control,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = useForm({
        mode: 'all',
        defaultValues: {
            picture: userDataProp?.user?.profilePhoto ? [{ url: userDataProp?.user?.profilePhoto || '' }] : [],
            firstName: userDataProp?.user?.firstName || '',
            lastName: userDataProp?.user?.lastName || '',
            dob: userDataProp?.user?.dob?.toString().split('T')[0] || '',
            familyCode: userDataProp?.user?.familyCode || '',
            establishmentName: userDataProp?.user?.establishmentName || '',
            emailId: userDataProp?.user?.emailId || '',
        },
    });

    useEffect(() => {
        const handleReturn = () => {
            if (onReturn) onReturn();
        };

        setTitle(t('Gérer mes informations personnelles'));
        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, []);

    const onSubmit = async (data: any) => {
        const res = await saveUser(data, userDataProp?.token as string);
        if (res && userDataProp.updateUser) {
            userDataProp.updateUser({ ...userDataProp, user: res });
        }
        if (onReturn) onReturn();
    };

    const required = t('Champ obligatoire.');

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={style.subTitle}>{t('Cliquez sur le cercle pour ajouter ou modifier votre photo. Cliquez sur un champ pour modifier vos informations.')}</div>
                <FormGroup inline>
                    <UploadInput control={control} register={register('picture')} pathStorage='users' />
                </FormGroup>

                <FormGrid>
                    <FormGroup label={t('Prénom') as string} error={errors.firstName} required>
                        <Input register={register('firstName', { required })} />
                    </FormGroup>
                    <FormGroup label={t('Nom') as string} error={errors.lastName} required>
                        <Input register={register('lastName', { required })} />
                    </FormGroup>
                </FormGrid>

                <FormGroup label={t('Date de naissance') as string} error={errors.dob} required>
                    <Input register={register('dob', { required })} type='date' />
                </FormGroup>

                <FormGroup label={t(`Nom de l'établissement`) as string} error={errors.establishmentName}>
                    <Input register={register('establishmentName')} readOnly />
                </FormGroup>

                <FormGroup label={t('Email') as string} error={errors.emailId}>
                    <Input register={register('emailId')} readOnly />
                </FormGroup>

                <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                    <Button theme='PRIMARY' disabled={!isValid} action={handleSubmit(onSubmit)} label={t('Enregistrer les modifications')} />
                    {/* <Button theme='PRIMARY' action={() => console.log('change password')} label={t('Changer mon mot de passe')} /> */}
                </ButtonsContainer>
            </form>
        </>
    )
};

export default withTranslation()(Account);