import { withTranslation } from 'react-i18next';
import styles from './style.module.css';
import { Button } from '../../components/Button/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

interface VideoProblemProps {
    onClose: () => void;
    redirectUrl: string;
    t: any;
}

export default withTranslation()(({ t, onClose, redirectUrl }: VideoProblemProps) => {
    return (
        <div className={styles.container}>
            <FontAwesomeIcon icon={faCircleInfo} className={styles.icon} />
            <div className={styles.title}>{t(`“Vidéo non disponible”\nou\n“Vidéo privée”`)}</div>
            <div className={styles.subTitle}>{t('Plusieurs raisons à cela :')}</div>
            <div className={styles.text}>
                {t(`1. Vidéo supprimée dans Youtube par son propriétaire
2. Droit d’accès à la vidéo modifié dans Youtube par son propriétaire (vidéo publique devenue privée)
3. Vous tentez de visionner cette vidéo depuis un Iphone ou Ipad... Apple ne le permet pas pour certains appareils. 😔`)}
            </div>
            <div className={styles.subTitle}>{t(`Plusieurs options s’offrent à vous :`)}</div>
            <div className={styles.text}>
                {t(`1. Supprimer cette vidéo de votre librairie médias
2. La regarder sur votre Iphone ou Ipad directement depuis le site ou App Youtube 😊
3. La regarder depuis un smartphone ou tablette Android 😂 ou encore depuis un ordinateur.`)}
            </div>

            <div className={styles.buttonsContainer}>
                {!!redirectUrl && <Button theme='SECONDARY' label={t('Regarder sur YouTube')} action={() => {
                    window.open(redirectUrl, '_blank');
                }} />}
                <Button label={t('Fermer cette fenêtre')} action={onClose} />
            </div>
        </div>
    );
});