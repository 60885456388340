import { withTranslation, WithTranslation } from "react-i18next";
import { useNavigation } from '../../pages/ContextMenu';
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import { ProfileStatistics } from "../ProfileStatistics";
import { Patient, PatientConvert } from "../../models/Patient";
import Action from "../Action";
import ListMembers from "../ListMembers";
import { getAll } from "../../stores/User";
import { getSenior } from "../../stores/Establishment";
import allBenef from '../../assets/allBenef.jpg';
import ProfilePicture from "../ProfilePicture";
import style from './style.module.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import Maintenance from "./maintenance";

interface StatisticsProps extends WithTranslation {
    onReturn?: () => void;
}

const Statistics = ({ t, onReturn }: StatisticsProps) => {
    const { subscribe, setTitle } = useNavigation();
    const [userSeniorList, setUserSeniorList] = useState<Patient[]>();
    const [selectedUsers, setSelectedUsers] = useState<Patient[]>();
    const userDataProp = useContext(UserContext);
    const user = userDataProp?.user;
    const role = user?.role;

    useEffect(() => {
        const fetchAllUser = async () => {
            const allUsers = await getAll(userDataProp?.token ?? '');
            setUserSeniorList(allUsers.map(PatientConvert));
        };

        const fetchSeniorList = async () => {
            const seniorList = await getSenior(userDataProp?.token ?? '');
            setUserSeniorList(seniorList?.data?.map(PatientConvert));
        }

        if (role === 'ADMIN') {
            fetchAllUser();
        } else if (role === 'THERAPIST') {
            fetchSeniorList();
        }
    }, [user]);

    useEffect(() => {
        const handleReturn = () => {
            if (selectedUsers) {
                setSelectedUsers(undefined);
                return;
            }
            if (onReturn) onReturn();
        };

        setTitle(t('Voir mes statistiques'));
        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, [selectedUsers]);

    if (!role) {
        return null;
    }

    if (role === 'ADMIN') {
        return <Maintenance />
        // return <ProfileStatistics userList={userSeniorList} />;
    }

    if (['PATIENT', 'FAMILY'].includes(role)) {
        return <Maintenance />
        // return <ProfileStatistics userList={user ? [PatientConvert(user)] : user} />;
    }

    if (role === 'THERAPIST' && !selectedUsers && userSeniorList) {
        return (
            <div>
                <div className={style.subTitle}>{t('Sélectionnez une personne accompagnée pour découvrir ses statistiques d’utilisation, de jeu et évolution.')}</div>
                <Action action={() => setSelectedUsers(userSeniorList)} className={style.allBenef}>
                    <ProfilePicture picture={allBenef} />
                    <div className={style.allBenefTitle}>{t('Tous mes bénéficiaires')}</div>
                    <FontAwesomeIcon icon={faChevronRight} />
                </Action>
                <ListMembers
                    members={userSeniorList}
                    onMemberClick={(user) => setSelectedUsers([PatientConvert(user)])}
                />
            </div>
        );
    } else if (role === 'THERAPIST' && selectedUsers) {
        return <Maintenance />
        // return <ProfileStatistics userList={selectedUsers} />;
    }


    return null;
};

export default withTranslation()(Statistics);
