import { WithTranslation, withTranslation } from 'react-i18next';
import style from './style.module.css';
import { useForm } from 'react-hook-form';
import UploadInput from '../UploadInput';
import FormGroup from '../FormGroup';
import Input from '../Input/index';
import ButtonsContainer from '../ButtonsContainer';
import Button from '../Button/index';
import { useEffect, useState } from 'react';
import { VideoPlayer } from '../VideoPlayer';
import { useNavigation } from '../../pages/ContextMenu';

interface ImportVideoProps extends WithTranslation {
    onReturn: () => void;
    onImport: (data: any) => void;
    data: any;
};

const ImportVideo = ({ t, onReturn, onImport, data: toEdit }: ImportVideoProps) => {
    const { setTitle } = useNavigation();
    const [subTitle, setSubTitle] = useState(t('Cliquez sur + pour importer une vidéo.'));
    const {
        watch,
        register,
        control,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = useForm<{ video: any, fileName: string }>({
        mode: 'all',
        defaultValues: {
            video: [],
            fileName: '',
        },
    });

    useEffect(() => {
        if (toEdit) {
            setValue('video', toEdit);
            setValue('fileName', toEdit?.[0]?.title);
        }
    }, [toEdit]);

    const onSubmit = (data: any) => {
        onImport([{
            url: data.video[0].url,
            fileName: data.fileName || '',
            thumbnail: data.video[0].thumbnail,
        }]);
    };

    const video = watch('video');

    useEffect(() => {
        if (video.length) {
            setTitle('Saisir un titre');
            setSubTitle(t(`- Option -
Saisissez un titre court et explicite.`));
        }
    }, [video]);

    return (
        <>
            <div className={style.subTitle}>{subTitle}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                {!!video.length && <FormGroup error={errors.fileName}>
                    <Input placeholder={t('Saisir un titre') as string} register={register('fileName')} />
                </FormGroup>}

                {!toEdit?.[0] ? <FormGroup required>
                    <UploadInput
                        autoOpen
                        type='VIDEO'
                        control={control}
                        register={register('video', { required: true })}
                        pathStorage='videos'
                        plusText='Importer une vidéo'
                    />
                </FormGroup> : <div className={style.videoContainer}>
                    <VideoPlayer url={toEdit?.[0]?.url} />
                </div>}

                {!!video.length && <FormGroup label={t('Saisissez un titre cours pour votre vidéo') as string} error={errors.fileName}>
                    <Input register={register('fileName')} />
                </FormGroup>}

                {!!video.length && <ButtonsContainer className={style.buttonsContainer} position='CENTER'>
                    <Button className={style.fullWidth} size='MEDIUM' theme='SECONDARY' disabled={!isValid} action={handleSubmit(onSubmit)} label={t('Continuer')} />
                </ButtonsContainer>}
            </form>
        </>
    );
};

export default withTranslation()(ImportVideo);