import { WithTranslation, withTranslation } from "react-i18next";
import { useNavigation } from "../../pages/ContextMenu";
import { Button } from "../Button/index";
import { useContext, useEffect, useState } from "react";

import style from './style.module.css';
import { UserContext } from "../../providers/UserProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { deleteUser } from "../../stores/User";
import { useWindow } from "../../contexts/WindowContext";

interface DeleteAccountProps extends WithTranslation {
    onReturn?: () => void;
}

const DeleteAccount = ({ t, onReturn }: DeleteAccountProps) => {
    const { subscribe, setTitle } = useNavigation();
    const userDataProp = useContext(UserContext);

    useEffect(() => {
        const handleReturn = () => {
            if (onReturn) onReturn();
        };

        setTitle(t('Supprimer mon compte'));
        const unsubscribe = subscribe(handleReturn);
        return () => unsubscribe();
    }, []);

    const onDelete = async () => {
        await deleteUser(userDataProp?.token ?? '')
            .then(() => {
                userDataProp?.updateUser && userDataProp.updateUser({
                    ...userDataProp,
                    user: { ...userDataProp?.user, needDelete: true },
                });
            })
            .catch((e) => console.log(e));
    };

    if (userDataProp?.user?.needDelete) {
        return (
            <>
                <div className={style.title}>
                    {t(`Votre compte a bien été supprimé.`)}
                </div>
                <div className={style.subTitle}>
                    {t(`Et maintenant ?
Votre demande sera traitée sous un délai de 24 à 48h.

Nous sommes tristes de vous voir partir. Aidez nous à nous améliorer en nous indiquant les raisons de votre départ : hello@stimulin.fr. Merci.

Vous n’avez plus rien à faire.`)}
                </div>
                <Button className={`${style.fullWidth} ${style.button}`} size='MEDIUM' label={t(`C'est noté, merci !`)} action={() => onReturn && onReturn()} />
            </>
        );
    }

    return (
        <>
            <div className={style.subTitle}>
                {t(`Vous avez la possibilité de supprimer votre compte Stimul’in en cliquant sur le bouton ci-dessous.

Si vous cliquez sur ce bouton :

→ votre compte sera définitivement supprimé sous 24 à 48h maximum
→ toutes vos données personnelles associées (photos, vidéos, prénom, nom,...) seront définitivement supprimées

Pour toute demande d’informations complémentaires, contactez l’équipe Stimul’in : hello@stimulin.fr`)}
            </div>
            <Button className={`${style.fullWidth} ${style.button}`} size='MEDIUM' label='Supprimer mon compte' action={() => onDelete()} />
        </>
    );
};

export default withTranslation()(DeleteAccount);