import Action from "../Action";
import GameTypeCard from "../GameTypeCard";
import style from "./style.module.css";
import { WithTranslation, withTranslation } from "react-i18next";
import createGame from './assets/createGame.svg';
import ContextMenu from "../../pages/ContextMenu";
import { useMenu } from "../../contexts/MenuContext";

interface GameTypeGridProps extends WithTranslation {
    items: {
        titre: string;
        Illustration: React.FC<React.SVGProps<SVGSVGElement>>;
        description?: string;
        Logo: React.FC<React.SVGProps<SVGSVGElement>>;
        action: string;
        disabled?: boolean;
    }[];
};

const GameTypeGrid = ({ t, items }: GameTypeGridProps) => {
    const { openMenu, closeMenu } = useMenu();

    const handleMenu = (initialPage: string) => {
        if (openMenu && closeMenu) {
            openMenu({
                component: () => <ContextMenu
                    initialPage={initialPage}
                    onClose={() => closeMenu()}
                />,
            });
        }
    };

    return (
        <div className={style.grid}>
            <Action action={() => handleMenu('CREATE')} className={style.album} style={{ backgroundImage: `url(${createGame})` }}>
                <span className={[style.ctaTitle, style.white].join(' ')}>{t('Créer un jeu')}</span>
            </Action>
            {items?.map(({ titre, Illustration, description, Logo, action, disabled }) => (
                <GameTypeCard
                    key={titre}
                    titre={titre}
                    action={action}
                    Illustration={Illustration}
                    description={description}
                    Logo={Logo}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};

export default withTranslation()(GameTypeGrid);